import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import UserContext from "./UserContext";
import { useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Login from "./Components/Login/Login";
import { GoogleOAuthProvider } from "@react-oauth/google";
import AIAnswerReviewB2B from './Components/AnswerReviewB2B/AIAnswerReviewB2B';
import PdfEditorPage from './Components/PdfEditorPage';
import PdfDataArrayContext from "./PDFContext";
import PdfViewerPage from './Components/PdfViewerPage';
import QuestionUpload from './Components/QuestionUpload';
import PdfPageViewer from "./Components/PageView/PdfPageView";
import PdfPageView from "./Components/PageView/PdfPageView";

const darkTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#ffffff",
    },
    secondary: {
      main: "#f50057",
    },
    background: {
      default: "#ffffff",
      paper: "#F2F9F6",
    },
  },
  overrides: {
    MuiAppBar: {
      colorInherit: {
        backgroundColor: "#FFFBF5",
        color: "#fff",
      },
    },
  },
  props: {
    MuiAppBar: {
      color: "inherit",
    },
  },
  typography: {
    fontFamily: "Quicksand",
  },
});

function App() {
  const userState = useState({});
  const [pdfData, setPdfData] = useState({
    answer_sheet_status: [],
    stats: [],
  });
  

  return (
    <ThemeProvider theme={darkTheme}>
      <GoogleOAuthProvider clientId="need_to_add_client_id">
        <UserContext.Provider value={userState}>
        <PdfDataArrayContext.Provider value={[pdfData, setPdfData]}>
          <CssBaseline />
          <Router>
            <Routes>
            <Route path="/edit/:mains_answer_sheet_hash/:user_id/:pdf_name" element={<PdfEditorPage />} />
              <Route exact path="/login" element={<Login />}></Route>
              <Route exact path="/" element={<AIAnswerReviewB2B />}></Route>
              <Route exact path='/question-upload' element={<QuestionUpload />}></Route>
              {/* <Route exact path="/home" element={<Home />}></Route> */}
              {/* <Route exact path="/" element={<AnswerReview />}></Route> */}
              <Route path="/view/:id/:user_id/:pdf_name"  element={<PdfViewerPage/>} >
              </Route>
              <Route path="/pdfview/:mains_answer_sheet_hash/:user_id/:pdf_name" element={<PdfPageViewer />}></Route>
              <Route path="/pdfedit/:mains_answer_sheet_hash/:user_id/:pdf_name" element={<PdfPageView />}></Route>

              {/* <Route path="/pdfview/:id/:user_id/:pdf_name" element={<PdfPageView responseFromGoogleLogin={responseFromGoogleLogin}/>}></Route> */}
            </Routes>
          </Router>
          </PdfDataArrayContext.Provider>
        </UserContext.Provider>
      </GoogleOAuthProvider>

    </ThemeProvider>
  );
}

export default App;
