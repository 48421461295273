import React from 'react';
import EmailIcon from '@mui/icons-material/Email';
import TelegramIcon from '@mui/icons-material/Telegram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Link } from "react-router-dom";
import './footer.css';

const Footer = () => {
    return (
        <div className='footer-base'>
            {/* <div className="footer-links"> */}
                {/* <Link to="/terms-and-condition">Terms and Condition</Link> */}
                {/* <span className="divider">|</span> */}
                {/* <Link to="/privacy-policy">Privacy Policy</Link> */}
            {/* </div> */}
            {/* <hr className="footer-divider" /> */}
            <div className="footer-info">
                <span>2024 © KPIAS, All rights reserved</span>
            </div>
        </div>
    );
}

export default Footer;
