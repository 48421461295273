const endpoint = "https://collectorbabu.com/api";

const apiData = {
  getWidgetContent: endpoint + "/widget/content",
  getWidgetContentDetails: endpoint + "/content_details",
  editWidgetSource: endpoint + "/widget/edit_source",
  getWidgetNames: endpoint + "/widget/list",
  userWidgetAction: endpoint + "/user/user_widget_action",
  getUserRemovedRules: endpoint + "/user/user_removed_rules",
  performUserWidgetAction: endpoint + "/user/user_widget_action",
  getUserWidgetActions: endpoint + "/user/get_user_widget_actions",
  undoUserWidgetActions: endpoint + "/user/undo_user_widget_actions",
  loginViaSso: endpoint + "/user/login_via_sso",
  readerParse: endpoint + "/reader/parse",
  pyqMatch: endpoint + "/pyq/match",
  mainsAnswerReview: endpoint + "/mains/answer_review",
  questionsForDay: endpoint + "/mains/questions_for_day",
  pay: endpoint + "/payment/pay",
  paymentStatus: endpoint + "/payment/status",
  getReferralStats: endpoint + "/user/get_referral_stats",
  getReferralCode: endpoint + "/user/get_referral_code",
  applyReferralCode: endpoint + "/user/apply_referral_code",
  getPaymentAmount: endpoint + "/user/get_payment_amount",
};

export { apiData, endpoint };
