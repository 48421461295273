import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import UserContext from "../../UserContext";
import Cookies from "js-cookie";
import { useNavigate, useLocation } from "react-router-dom";
import { isLoggedIn } from "../../util";
import landing from './landing.png';

function Header() {
  const [loggedInUserDetails, setLoggedInUserDetails] =
    React.useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    Cookies.remove("google_info");
    Cookies.remove("user_name");
    Cookies.remove("user_id");
    Cookies.remove("email");
    setLoggedInUserDetails(Object.assign({}));
    navigate("/login");
  };


  React.useEffect(() => {
    wrapperIsLoggedIn();
  }, []);

  const wrapperIsLoggedIn = async () => {
    let userData = await isLoggedIn();
    if (userData) {
      setLoggedInUserDetails(userData);
    }
  };

  const handleLogin = () => {
    navigate("/login");
  };

  const handleNavigation = () => {
    if (location.pathname === "/") {
      navigate("/question-upload");
    } else if (location.pathname === "/question-upload") {
      navigate("/");
    }
  };


  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            sx={{
              flexGrow: 0,
              display: "flex",
              mr: 2,
            }}
          >
            <img
              src={landing}
              width="300"
              height="44.4"
              alt="Logo"
              className="narayana-logo"
            />
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}></Box>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}></Box>

          <Box
            sx={{ flexGrow: 0, display: "flex", alignItems: "center", gap: 2 }}
          >
          <Button
              color="inherit"
              variant="outlined"
              onClick={handleNavigation}
              sx={{
                backgroundColor: "#2196f3",
                "&:hover": {
                  backgroundColor: "#1976d2",
                },
              }}
            >
              {location.pathname === "/" ? "Question Upload" : "Home"}
            </Button>
            <Typography>
              {loggedInUserDetails?.name
                ? "Hello, " + loggedInUserDetails.name
                : null}
            </Typography>
            {loggedInUserDetails.user_id ? (
              <Button
                color="inherit"
                variant="outlined"
                onClick={handleLogout}
                sx={{
                backgroundColor: "#ff9800",
                "&:hover": {
                  backgroundColor: "#e68900",
                },
              }}
              >
                Logout
              </Button>
            ) : (
              <Button
                color="inherit"
                variant="outlined"
                onClick={handleLogin}
                sx={{
                backgroundColor: "#ff9800",
                "&:hover": {
                  backgroundColor: "#e68900",
                },
              }}
              >
                Login
              </Button>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;
