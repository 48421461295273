import React, { useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
// import { Document, Page } from 'react-pdf/dist/entry.webpack';
// import { pdfjs } from 'react-pdf';
// import PdfCompo from './PdfCompo';
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { IoIosArrowForward, IoIosArrowBack,IoIosMenu,IoMdList,IoIosAdd,IoMdRemove,IoIosArrowRoundBack,IoIosArrowRoundForward } from "react-icons/io";
import { FaListUl } from "react-icons/fa";
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import annotationTemplate from '../AITable/annotationsTemplate';
import { MdEdit } from "react-icons/md";
import './index.css';
// Set the workerSrc for pdfjs

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function PdfPageView() {
    const { mains_answer_sheet_hash, user_id, pdf_name } = useParams();
    const [pdfUrl, setPdfUrl] = useState('');
    const [annotations, setAnnotations] = useState([]);
    const [indexPageMapping,setIndexPageMapping]=useState({});
    const [showPreview, setShowPreview] = useState(true);
    const [commentAsList, setCommentAsList] = useState(true);
    const [annotationsFetched, setAnnotationsFetched] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [numPages, setNumPages] = useState(null);
    const [editingAnnotationId, setEditingAnnotationId] = useState(null);
    const [newAnnotationText, setNewAnnotationText] = useState('');
    const [zoomLevel, setZoomLevel] = useState(100);
    const [scale, setScale] = useState(1);
    const [renderedPageNumber, setRenderedPageNumber] = useState(null);
    const [renderedScale, setRenderedScale] = useState(null);
    const pageRefs = useRef([]);
    const annotationRefs = useRef([]);

    useEffect(() => {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
      };
    
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const visiblePageNumber = pageRefs.current.indexOf(entry.target) + 1;
            setPageNumber(visiblePageNumber);
          }
        });
      }, options);
    
      pageRefs.current.forEach((pageRef) => {
        if (pageRef) {
          observer.observe(pageRef);
        }
      });
    
      return () => {
        pageRefs.current.forEach((pageRef) => {
          if (pageRef) {
            observer.unobserve(pageRef);
          }
        });
      };
    }, [numPages]);

    useEffect(() => {
      if (pageRefs.current[pageNumber - 1]) {
        pageRefs.current[pageNumber - 1].scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
      if (commentAsList && annotationRefs.current[pageNumber - 1]) {
        annotationRefs.current[pageNumber - 1].scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, [pageNumber, commentAsList]);

    const fetchAnnotations = async () => {
      try {
        const response = await axios.post(
          'https://collectorbabu.com/api/mains/mains_get_reviews_in_text',
          {
            user_id: user_id,
            mains_answer_sheet_hash: mains_answer_sheet_hash
          },
          {
            headers: {
              'Content-Type': 'application/json',
              accept: 'application/json'
            }
          }
        );
  
        const annotationsResponse = response.data;
        setAnnotations(annotationsResponse);
        setAnnotationsFetched(true);
      } catch (error) {
        console.error('Error fetching annotations:', error);
      }
    };
    useEffect(() => {
      fetchAnnotations();
    }, [user_id, mains_answer_sheet_hash]);
    

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.post(
              'https://collectorbabu.com/api/mains/mains_answer_sheet_download',
              { user_id: user_id, mains_answer_sheet_hash: mains_answer_sheet_hash }, 
            );
            const pdfLink = response.data.pdf_url;
            setPdfUrl(pdfLink);
            // console.log(pdfLink);
            // setLoading(false);
          } catch (error) {
            console.error('Error fetching PDF:', error);
          }
        };
    
        fetchData();
    
        // Clean up the URL object when component unmounts
        // return () => {
        //   if (pdfUrl) {
        //     URL.revokeObjectURL(pdfUrl);
        //   }
        // };
      }, []);
  
    const handlePageChange = (page) => {
      setPageNumber(page);
    };
  
    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
      pageRefs.current = Array(numPages).fill(null);
    }
  
    const changePage = (param) => {
      if (param === "prev" && pageNumber > 1) {
        setPageNumber((prev) => prev - 1);
      }
      if (param === "next" && pageNumber < numPages) {
        setPageNumber((prev) => prev + 1);
      }
    };
   
    // const handleDownload = () => {
    //     window.open(pdfUrl, '_blank');
    //   };
    const downloadPDF = async () => {
      try {
        // setLoadingActions(prevLoadingActions => ({ ...prevLoadingActions, [id]: true }));
        const response = await axios.post(
          "https://collectorbabu.com/api/mains/mains_answer_sheet_download",
          { user_id: user_id, mains_answer_sheet_hash: mains_answer_sheet_hash },
        );
        
        const pdfLink = response.data.pdf_url;
        const pdfResponse = await axios.get(
          pdfLink, { responseType: "blob" }
        );
    
        const blob = new Blob([pdfResponse.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(blob);
    
        // Create a link to download the PDF
        const link = document.createElement('a');
        link.href = fileURL;
        link.setAttribute('download', pdf_name || 'collectorBabu.pdf'); 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); 
    
        // Open the PDF in a new window
        const newWindow = window.open();
        newWindow.location.href = fileURL;
    
        // Clean up the object URL after a delay to allow the new window to load the PDF
        setTimeout(() => {
          URL.revokeObjectURL(fileURL);
        }, 100);
    
      } catch (error) {
        console.error("Error downloading PDF:", error);
      } finally {
        // setLoadingActions(prevLoadingActions => ({ ...prevLoadingActions, [id]: false }));
        // setOpen(true);
      }
    };
    

      const handleEditClick = (annotationId, annotationText) => {
        // console.log(annotationId);
        setEditingAnnotationId(annotationId);
        setNewAnnotationText(annotationText);
      };
    
      const handleInputChange = (event) => {
        setNewAnnotationText(event.target.value);
      };
    
      const handleSaveClick = async(annotationId) => {
        const updatedAnnotations = annotations.map((annotation) =>
          annotation.annotation_id === annotationId
            ? { ...annotation, annotations: newAnnotationText }
            : annotation
        );
    
        const annotationToUpdate = updatedAnnotations.find(
          (annotation) => annotation.annotation_id === annotationId
        );
        const editedReviews =  [
            {
              annotation_id: annotationToUpdate.annotation_id,
              annotations: annotationToUpdate.annotations,
              page_number: annotationToUpdate.page_number || null,
              annotation_details: annotationToUpdate.annotation_details || ''
            }
          ];
        try {
    
          const response = await axios.post(
            "https://collectorbabu.com/api/mains/mains_save_edited_reviews",
            {
              user_id: user_id,
              mains_answer_sheet_hash: mains_answer_sheet_hash,
              edited_reviews: editedReviews,
            }
          );
    
          fetchAnnotations();
        } catch (error) {
          console.error("Error saving edited reviews:", error);
        }
        setEditingAnnotationId(null);
        setNewAnnotationText('');
      };
    
      const handleCancelClick = () => {
        setEditingAnnotationId(null);
        setNewAnnotationText('');
      };

        function changeScale(offset) {
    setScale((prevScale) => prevScale + offset);
  }

      function decreaseScale() {
        // changeScale(-0.1);
        setScale((prevZoom) => Math.max((prevZoom - .1).toFixed(1), .5))
      }
    
      function increaseScale() {
        // changeScale(0.1);
        setScale((prevZoom) => Math.min((prevZoom + .1).toFixed(1), 2))
      }
    
      const isLoading =
        renderedPageNumber !== pageNumber || renderedScale !== scale;
    
      const handleZoomIn = () => setZoomLevel((prevZoom) => Math.min(prevZoom + 25, 200));
      const handleZoomOut = () => setZoomLevel((prevZoom) => Math.max(prevZoom - 25, 25));

      const handleWheel = (event) => {
        if (event.ctrlKey) {
          event.preventDefault();
          // setZoomLevel((prevZoom) => event.deltaY < 0 ? Math.min(prevZoom + 10, 200) : Math.max(prevZoom - 10, 25));
          setScale((prevZoom) => event.deltaY < 0 ? Math.min(prevZoom + .1, 2) : Math.max(prevZoom - .1, .5));
        }
      };

      useEffect(() => {
        const pdfContainer = document.getElementById('pdf-container');
        pdfContainer.addEventListener('wheel', handleWheel);

        return () => {
          pdfContainer.removeEventListener('wheel', handleWheel);
        };
      }, []);
    
  
    return (
    <>
          {/* bg-[#F8FAFC]bg-[#abe6a4] */}
        <div className="w-full h-screen flex justify-start items-start overflow-hidden bg-[#262658]">
          <div className='flex w-full max-h-screen'>
            <div className="w-[75%] h-[100%] flex flex-col">
                <div className="w-full h-[10%] bg-[#00003C]">
                  <div 
                    className=" h-16 py-2 px-4 flex justify-between items-center"
                    style={{
                      background: 'linear-gradient(to bottom, rgb(22,26,41)20%, black 100%)',
                    }}
                  >
                    <div className='flex items-center gap-2'>
                      <IoIosMenu className='cursor-pointer text-2xl text-white' onClick={()=>setShowPreview(!showPreview)}/> 
                      <div className="font-semibold text-lg text-white">{pdf_name}</div>
                    </div>
                    <div className="flex justify-center items-center gap-2 ml-[-2]">
                      <IoIosArrowBack
                          className="cursor-pointer text-xl text-white"
                          onClick={() => changePage("prev")}
                      />
                      <div className="px-3 rounded bg-white">{pageNumber} / {numPages}</div>
                      {/* / */}
                      {/* <div className="px-3 rounded bg-white">{numPages}</div> */}
                      <IoIosArrowForward
                          className="cursor-pointer text-xl text-white"
                          onClick={() => changePage("next")}
                      />
                    </div>
                    <div className='flex gap-2'>
                       <IoMdRemove className='text-xl text-white'onClick={decreaseScale}/>
                       <div className='bg-white px-1 rounded'>{(scale*100).toFixed(0)}%</div>
                       <IoIosAdd className='text-xl text-white'onClick={increaseScale}/>
                    </div>
                    <div>
                      <button className="bg-black text-white p-4 cursor-pointer py-1 rounded shadow-md shadow-gray-700"  onClick={downloadPDF}>
                          Download
                      </button>
                    </div>
                </div>
                
                <div id='pdf-container' className=" w-full h-screen overflow-auto relative">
                  <Document
                    className="flex flex-col justify-start items-center "
                    file={pdfUrl}
                    onLoadSuccess={onDocumentLoadSuccess}
                    onLoadError={(error) => console.error('Failed to load PDF:', error)}
                  >
                    {Array.from({ length: numPages }, (_, index) => (
                      <div
                        key={index}
                        ref={(el) => (pageRefs.current[index] = el)}
                        onClick={() => {
                          setPageNumber(index + 1); 
                        }}
                        className={` cursor-pointer relative rounded my-2 ${pageNumber === index + 1 ? "border-green-700" : ""}`}
                      >
                        <Page
                           width={1000 * (zoomLevel / 100)}
                          pageNumber={index + 1} 
                        />
                       
                      </div>
                    ))}
                  </Document>
                  {/* <Document
                    className="flex flex-col justify-start items-center mb-5"
                    file={pdfUrl}
                    onLoadSuccess={onDocumentLoadSuccess}
                    onLoadError={(error) => console.error('Failed to load PDF:', error)}
                  >
                      
                        {isLoading && renderedPageNumber && renderedScale ? (
                          <Page
                            key={renderedPageNumber + "@" + renderedScale}
                            className="prevPage"
                            pageNumber={renderedPageNumber}
                            scale={renderedScale}
                            width={1000}
                          />
                        ) : null}
                        <Page
                          key={pageNumber + "@" + scale}
                          pageNumber={pageNumber}
                          onRenderSuccess={() => {
                            setRenderedPageNumber(pageNumber);
                            setRenderedScale(scale);
                          }}
                          scale={scale}
                          width={1000}
                        />
                  </Document>  */}
                  <div className='sticky bottom-[50%] z-20 flex justify-between items-center'>
                    <div className='ml-6 h-16 w-16 rounded-full bg-black shadow-md shadow-gray-700'>
                    <IoIosArrowRoundBack
                            className="cursor-pointer m-auto mt-14 text-6xl text-white"
                            onClick={() => changePage("prev")}
                            />
                    </div>
                    <div className='mr-6 h-16 w-16 rounded-full bg-black shadow-md shadow-gray-700'>
                    <IoIosArrowRoundForward
                            className="cursor-pointer m-auto mt-14 text-6xl text-white"
                            onClick={() => changePage("next")}
                            />
                    </div>
                    </div>
                </div>
                </div>
             
            </div>
            {/* <div className="w-[100%] bg-slate-100 p-4 h-full overflow-auto flex justify-center items-start">
                    <Document
                    file={pdfUrl}
                    onLoadSuccess={onDocumentLoadSuccess}
                    onLoadError={(error) => console.error('Failed to load PDF:', error)}
                    >
                    <Page pageNumber={pageNumber} width={700}/>
                    </Document>
                </div> */}
                
            <div className='border-l-black border-l-2  w-[25%] min-w-96 h-screen pb-4'>
                <div 
                  className='px-2 h-16 py-3 flex justify-between items-center'
                  style={{
                    background: 'linear-gradient(to bottom, rgb(22,26,41)20%, black 100%)',
                  }}
                >
                  <div className=" text-center text-white font-semibold text-lg">
                    Comments
                  </div>
                  <div className=''>
                  <IoMdList className='text-2xl text-white' onClick={()=> setCommentAsList(!commentAsList)}/>
                  </div>
                </div>
                <div className='h-[95%] overflow-auto mb-2 relative'>
                  {annotationsFetched && annotations.length > 0 ? (
                    !commentAsList ? (
                      <>
                    <div className=' flex flex-col justify-between h-[98%]'>
                      <div >
                      <span className="font-medium text-white"> Page {pageNumber}</span>
                      {annotations
                      .filter((_, idx) => idx + 1 === pageNumber)
                      .map((annotation, index) => (
                        <div key={index} className="p-3 border-2 gap-0 rounded-md mt-1 mb-3 h-[80%] overflow-auto shadow-md bg-white" onDoubleClick={() => handleEditClick(annotation.annotation_id, annotation.annotations)}>
                          <div className="flex justify-between items-center p-0 m-0">
                            <div className='flex flex-col'>
                            <p className=''>
                              <strong>Collector Babu</strong>
                            </p>
                            <p className="text-xs">
                              <small>{new Date(annotation.annotation_created_at).toLocaleString()}</small>
                            </p>
                            </div>
                            <MdEdit className='text-2xl-cursor' onClick={() => handleEditClick(annotation.annotation_id, annotation.annotations)} />
                          </div>
                          {editingAnnotationId === annotation.annotation_id ? (
                            <div className='h-96'>
                              <textarea
                                value={newAnnotationText}
                                onChange={handleInputChange}
                                className="w-full p-2 border rounded h-[90%]"
                              />
                              <div className="flex justify-end gap-2 mt-2">
                                <button
                                  onClick={handleCancelClick}
                                  className="bg-gray-300 hover:bg-stone-400 text-black px-4 py-1 rounded"
                                >
                                  Cancel
                                </button>
                                <button
                                  onClick={() => handleSaveClick(annotation.annotation_id)}
                                  className="bg-[#14BA78] hover:bg-[#189c67] text-white px-4 py-1 rounded"
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          ) : (
                            annotation.annotations &&
                            annotation.annotations.split('\n').map((line, idx) => (
                              <p key={idx} className="text-sm">
                                {line}
                              </p>
                            ))
                          )}
                        </div>
                      ))}
                      </div>
                      <div className="absolute bottom-0 bg-slate-300 flex w-full justify-end gap-4 mt-2 px-4 py-2">
                        <button
                          onClick={() => setPageNumber(pageNumber - 1)}
                          disabled={pageNumber === 1}
                          className={`px-4 py-2 shadow-md shadow-slate-600 rounded ${pageNumber === 1 ? 'bg-gray-300' : 'bg-black hover:bg-[#323638] text-white'}`}
                        >
                          Previous
                        </button>
                        <button
                          onClick={() => setPageNumber(pageNumber + 1)}
                          disabled={pageNumber === numPages}
                          className={`px-4 py-2 shadow-md shadow-slate-600 rounded ${pageNumber === numPages ? 'bg-gray-300' : 'bg-black hover:bg-[#323638] text-white'}`}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                    {/* <div className=' mt-1 border-2' /> */}
                    </>
                  ):(
                    <>
                    <div >
                      {annotations.map((annotation, index)=>{
                        return (
                        // <div key={index} ref={(el) => (annotationRefs.current[index] = el)} onClick={() => handlePageChange(index + 1)} onDoubleClick={() => handleEditClick(annotation.annotation_id, annotation.annotations)} className="flex flex-col">
                        <div
                          key={index} 
                          ref={(el) => (annotationRefs.current[index] = el)} 
                          onClick={() => handlePageChange(index + 1)} 
                          onDoubleClick={(e) => {
                            e.stopPropagation(); // Prevent onClick from firing
                            handleEditClick(annotation.annotation_id, annotation.annotations);
                          }}
                          className="flex flex-col"
                        >
                            <span className="font-medium text-white"> Page {index + 1}</span>
                            <div className="p-3 border-2 gap-0 rounded-md mt-1 border-b-stone-100 shadow-md bg-white">
                            <div className="flex justify-between items-center p-0 m-0">
                            <div className='flex flex-col'>
                            <p className=''>
                              <strong>Collector Babu</strong>
                            </p>
                            <p className="text-xs">
                              <small>{new Date(annotation.annotation_created_at).toLocaleString()}</small>
                            </p>
                            </div>
                            <MdEdit className='text-2xl-cursor' onClick={() => handleEditClick(annotation.annotation_id, annotation.annotations)} />
                          </div>
                              {editingAnnotationId === annotation.annotation_id ? (
                        <div className='h-96'>
                          <textarea
                            value={newAnnotationText}
                            onChange={handleInputChange}
                            className="w-full p-2 border rounded h-[90%]"
                          />
                          <div className="flex justify-end gap-2 mt-2">
                                <button
                                  onClick={handleCancelClick}
                                  className="bg-gray-300 hover:bg-stone-400 text-black px-4 py-1 rounded"
                                >
                                  Cancel
                                </button>
                                <button
                                  onClick={() => handleSaveClick(annotation.annotation_id)}
                                  className="bg-[#14BA78] hover:bg-[#189c67] text-white px-4 py-1 rounded"
                                >
                                  Save
                                </button>
                              </div>
                        </div>
                      ) : (
                        annotation.annotations &&
                        annotation.annotations.split('\n').map((line, idx) => (
                          <p key={idx} className="text-sm">
                            {line}
                          </p>
                        ))
                      )}
                          </div>
                        </div>
                      )})}
                      <div className=" sticky z-20 bottom-0 bg-[#00003C] flex w-full justify-end gap-4 mt-2 px-4 py-2">
                        <button
                          onClick={() => setPageNumber(pageNumber - 1)}
                          disabled={pageNumber === 1}
                          className={`px-4 py-2 shadow-md shadow-slate-600 rounded ${pageNumber === 1 ? 'bg-gray-300' : 'bg-black hover:bg-[#323638] text-white'}`}
                        >
                          Previous
                        </button>
                        <button
                          onClick={() => setPageNumber(pageNumber + 1)}
                          disabled={pageNumber === numPages}
                          className={`px-4 py-2 shadow-md shadow-slate-600 rounded ${pageNumber === numPages ? 'bg-gray-300' : 'bg-black hover:bg-[#323638] text-white'}`}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                    <br className='mt-1'/>
                    </>
                  )
                  ) : (
                    <p className="p-3 text-gray-500">No comments for this pdf.</p>
                  )}
                </div>
            </div>
          </div>
        </div>
    </>
  );
}

export default PdfPageView;