import React, { useState, useContext } from 'react';
import axios from 'axios';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Select from 'react-select';
import { TailSpin } from 'react-loader-spinner';
import UserContext from "../../UserContext";

import './index.css';

const subjects = [
  { value: 'TELANGANAMOVEMENT', label: 'TELANGANAPCS' },
  { value: 'GS', label: 'GS' },
  { value: 'GS4', label: 'GS4' },
  { value: 'ESSAY', label: 'ESSAY' },
  { value: 'ANTHROPOLOGY', label: 'ANTHROPOLOGY' },
  { value: 'SOCIOLOGY', label: 'SOCIOLOGY' },
  { value: 'PSIR', label: 'PSIR' },
  { value: 'LAW', label: 'LAW' },
  { value: 'PSYCHOLOGY', label: 'PSYCHOLOGY' },
  { value: 'HISTORY', label: 'HISTORY' }
];

const QuestionPaperUpload = () => {
  const [loggedInUserDetails] = useContext(UserContext);

  const [formData, setFormData] = useState({
    user_id: loggedInUserDetails.user_id || '',
    subject: subjects[0] || { value: '', label: '' },
    client: 'kpias',
    assignment_name: '',
    question_paper: null,
  });

  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const handleChange = (selectedOption) => {
    setFormData({
      ...formData,
      subject: selectedOption || { value: '', label: '' },
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      question_paper: e.target.files[0] || null,
    });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!formData.subject || !formData.subject.value) {
      setError('Subject is required');
      setSnackbarMessage('Error uploading question paper.');
      setSnackbarSeverity('error');
      setLoading(false);
      setSnackbarOpen(true);
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append('question_paper', formData.question_paper);

    try {
      const res = await axios.post(
        `https://collectorbabu.com/api/template/save_and_extract_question?user_id=${formData.user_id}&subject=${formData.subject.value}&client=${formData.client}&assignment_name=${formData.assignment_name}`,
        formDataToSend,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      setResponse(res.data);
      setSnackbarMessage('Question paper uploaded successfully!');
      setSnackbarSeverity('success');
    } catch (err) {
      setError(err.response?.data?.detail || 'An error occurred');
      setSnackbarMessage('Error uploading question paper.');
      setSnackbarSeverity('error');
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  return (
    <>
      <Header/>
      <div className="question-paper-form">
        <h2>Question Paper Upload</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Subject:</label>
            <Select
              options={subjects}
              value={formData.subject}
              onChange={handleChange}
              isSearchable
              required
            />
          </div>

          <div className="form-group">
            <label>Test Code:</label>
            <input required type="text" name="assignment_name" value={formData.assignment_name} onChange={handleInputChange} />
          </div>

          <div className="form-group">
            <label>Question Paper (PDF):</label>
            <input type="file" name="question_paper" onChange={handleFileChange} required />
          </div>

          <button type="submit" className="submit-btn" disabled={loading}>
            {loading ? <TailSpin height="20" width="20" color="white" /> : 'Submit'}
          </button>
        </form>

        {response && <div className="response">
          <h1>Question paper uploaded successfully</h1>
        </div>}
        {error && <div className="error">{error}</div>}
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
      <Footer/>
    </>
  );
};

export default QuestionPaperUpload;
