import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PdfReader from "../AITable/PdfReader";
import { useParams, useNavigate } from "react-router-dom";
import {Oval} from 'react-loader-spinner'

const App = () => {
    const {id,user_id}=useParams();
  const [pdfUrl, setPdfUrl] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          'https://collectorbabu.com/api/mains/mains_answer_sheet_download',
          { user_id: user_id, mains_answer_sheet_hash: id },
        );
        console.log(response);
        const pdfLink = response.data.pdf_url;
        setPdfUrl(pdfLink);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching PDF:', error);
      }
    };

    fetchData();

    // Clean up the URL object when component unmounts
    return () => {
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl);
      }
    };
  }, []); // Empty dependency array to run only once

  return (
    <div className="pdf-loader-container">
    {loading ? (
      <div className="pdf-loader-loading">
        <Oval />
        <p>Your PDF is loading. Please hold on...</p>
      </div>
    ) : (
      <PdfReader className="pdfReader" url={pdfUrl} />
    )}
  </div>
  );
}

export default App;
