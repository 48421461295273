import { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import PdfEditor from "../PdfEditing/PdfEditing";
import { useParams } from "react-router-dom";
import { Oval } from 'react-loader-spinner';
import './index.css';
// import { PdfContext } from '../../PdfContext';

const App = () => {

  const { mains_answer_sheet_hash, user_id } = useParams();
  const [pdfUrl, setPdfUrl] = useState('');
  const [loading, setLoading] = useState(true);
  // const { pdfName } = useContext(PdfContext);
  // console.log(pdfName);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          'https://collectorbabu.com/api/mains/mains_answer_sheet_download',
          { user_id: user_id, mains_answer_sheet_hash: mains_answer_sheet_hash },
        );
        const pdfLink = response.data.pdf_url;
        setPdfUrl(pdfLink);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching PDF:', error);
        setLoading(false); 
      }
    };

    fetchData();

    return () => {
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl);
      }
    };
  }, []);


  return (
    <div className="pdf-loader-container">
      {loading ? (
        <div className="pdf-loader-loading">
          <Oval />
          <p>Your PDF is loading. Please hold on...</p>
        </div>
      ) : (
        <PdfEditor className="pdfReader" url={pdfUrl} />
      )}
    </div>
  );
};

export default App;
