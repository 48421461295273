import * as React from "react";
import { useEffect, useContext, useState } from 'react';
import axios from 'axios';
import PdfDataArrayContext from "../../PDFContext";
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import './index.css';
import { FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material';
import UserContext from "../../UserContext";
import { DownloadSimple, Eye, PencilSimple } from '@phosphor-icons/react'
import { isLoggedIn} from "../../util";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useMediaQuery, createTheme } from '@mui/material';
import Box from "@mui/material/Box";
import PropTypes from 'prop-types';
import clsx from 'clsx';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, FormGroup, FormControlLabel, Checkbox, IconButton } from '@mui/material';
// import Header from '../Header/Header';
import EditIcon from "@mui/icons-material/Edit";
import { MdEdit } from "react-icons/md";
import {filterData} from '../../data/filterData.js';
import dayjs from 'dayjs';
const { format, parseISO } = require('date-fns');


const DashboardContainer =()=>{

  const [loggedInUserDetails, setLoggedInUserDetails] = useContext(UserContext);
  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));


  useEffect(() => {
    wrapperIsLoggedIn();
  }, []);

  
  const wrapperIsLoggedIn = async () => {
    let userData = await isLoggedIn();
    if (userData) {
      setLoggedInUserDetails(userData);
    } else {
    //   setOpenLoginPrompt(true);
    }
  }; 

  return (
    <>
      {isMobile ? (
        <>
        <Dashboard />
        </>
      ):(  
        <>
        <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
          
          <div style={{ marginTop: '78px' }}> 
            <Dashboard />
          </div>
        </Box>
        
        </>
      )}
    </>
  );
}
const Dashboard=()=> {
    const [loggedInUserDetails, setLoggedInUserDetails] = useContext(UserContext);
    const [pdfData, setPdfData] = useContext(PdfDataArrayContext);
    const [loadingActions, setLoadingActions] = useState({});
    const [open, setOpen] = useState(false);
    const [page,setPage]=useState(0);
    const [total,setTotal]=useState(0);
    const [selectedOption, setSelectedOption] = useState('pdf');
    const [status, setStatus] = useState([]);
    const [subject, setSubject] = useState([]);
    const subjectFilter = filterData.subjectFilter;
    const statusFilter = filterData.statusFilter;
    // const [subjectFilter, setSubjectFilter] = useState(['GS', 'GS4', 'ESSAY', 'SOCIOLOGY', 'ANTHROPOLOGY', 'TELANGANAMOVEMENT', 'LAW']);
    // const [statusFilter, setStatusFilter] = useState(['Error', 'Completed', 'Uploaded', 'Uploading', 'Review']);
    const [openBackdrop, setOpenBackDrop] = useState(false);
    const [filterApplied, setFilterApplied] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);   
    const rowsPerPage = 10;
  const maxPageNumbersToShow = 10;


    
    const handleCloseBackDrop = () => {
      setOpenBackDrop(false);
    };
    
    const handleOpenBackDrop = () => {
      setOpenBackDrop(true);
    };

    const [widgetData, setWidgetData] = useState({
        uploaded: { title: "Uploaded File", count: 0, imgUrl: "/icons/file-text-icon.png" },
        queue: { title: "In Queue", count: 0, imgUrl: "/icons/file-question-icon.png" },
        completed: { title: "Processed File", count: 0, imgUrl: "/icons/file-check-icon.png" }
    });
    const pdfLength= pdfData.answer_sheet_status.length<10



    useEffect(() => {
        fetchData();
        // fetchImageData();
    }, [page, loggedInUserDetails,subject, status, startDate]);



    
    //function used to fetch pdf data
    const fetchData = async () => {
      if (!loggedInUserDetails.user_id) {
        return;
    }
        try {
            const response = await axios.post(
                "https://collectorbabu.com/api/mains/mains_get_answer_sheet_status",
                { user_id: loggedInUserDetails.user_id,subject, status, page, start_date: startDate, end_date: endDate ,domain:"kpias" }
            );
            setPdfData({
                answer_sheet_status: response.data.answer_sheet_status || [],
                stats: response.data.stats || [],
            });
            const totalPdfs = response.data.stats.total || 0;
            setTotalPages(Math.ceil(totalPdfs / rowsPerPage));      
            const stats = response.data.stats;
            setTotal(stats.total);
            setWidgetData(prevData => ({
              ...prevData,
              uploaded: { ...prevData.uploaded, count: stats.total || 0 },
              queue: { ...prevData.queue, count: stats.queue || 0 },
              completed: { ...prevData.completed, count: stats.completed || 0 }
            }));
        } catch (error) {
            console.error("Error fetching PDF list:", error);
        } finally {
            console.log("done need to add Loader");
        }
    };

//function used to view pdf by url
    // const handleView = async (url, id, pdf_name) => {
    //     try { 
    //         setLoadingActions(prevLoadingActions => ({ ...prevLoadingActions, [id]: true }));
    //         window.open(url, "_blank");      
    //     } catch (error) {
    //         console.error('Error viewing PDF:', error);
    //     } finally {
    //         setLoadingActions(prevLoadingActions => ({ ...prevLoadingActions, [id]: false }));
    //     }
    // };

//function used to view pdf by id
    const handleView = async (id,pdf_name) => {
      try {
        setLoadingActions(prevLoadingActions => ({ ...prevLoadingActions, [id]: true }));
        window.open(`/pdfview/${id}/${loggedInUserDetails.user_id}/${pdf_name}`, "_blank");
      } catch (error) {
        console.error('Error viewing PDF:', error);
      } finally {
        setLoadingActions(prevLoadingActions => ({ ...prevLoadingActions, [id]: false }));
      }
    };

    const handleEdit = async (id,pdf_name) => {
      try {
        setLoadingActions(prevLoadingActions => ({ ...prevLoadingActions, [id]: true }));
        window.open(`/pdfedit/${id}/${loggedInUserDetails.user_id}/${pdf_name}`, "_blank");
      } catch (error) {
        console.error('Error viewing PDF:', error);
      } finally {
        setLoadingActions(prevLoadingActions => ({ ...prevLoadingActions, [id]: false }));
      }
    };
    


    const downloadPDF = async (id, pdf_name) => {
        try {
            setLoadingActions(prevLoadingActions => ({ ...prevLoadingActions, [id]: true }));
            const response = await axios.post(
                "https://collectorbabu.com/api/mains/mains_answer_sheet_download",
                { user_id: loggedInUserDetails.user_id, mains_answer_sheet_hash: id },
            );
            const pdfLink = response.data.pdf_url;
            const pdfResponse = await axios.get(pdfLink, { responseType: "blob" });
            const blob = new Blob([pdfResponse.data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = fileURL;
            link.setAttribute('download', pdf_name || 'collectorBabu.pdf');
            document.body.appendChild(link);
            link.click();
            URL.revokeObjectURL(fileURL);
            document.body.removeChild(link);
        } catch (error) {
            console.error("Error downloading PDF:", error);
        } finally {
            setLoadingActions(prevLoadingActions => ({ ...prevLoadingActions, [id]: false }));
            setOpen(true);
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const getStatusStyle = (status) => {
      switch (status) {
        case "Error":
          return {
            color: "red",
            backgroundColor: "rgba(255, 0, 0, 0.1)",
            margin:"auto",
            marginTop:"13px",
            height:"18px",
            textAlign:"center",
            width:"140px",
            borderRadius:"18px",
            display:"flex",
            // alignItems:"center",
            // justifyContent:"center",
          };
        case "Uploaded":
          return {
            color: "blue",
            backgroundColor: "rgba(0, 0, 255, 0.1)",
            borderRadius:"18px",
            margin:"auto",
            marginTop:"13px",
            height:"18px",
            width:"140px",
            textAlign:"center",
            fontSize:"15px",
            display:"flex",
            // alignItems:"center",
            // justifyContent:"center",
          };
        case "Completed":
          return {
            color: "green",
            backgroundColor: "rgba(0, 128, 0, 0.1)",
            margin:"auto",
            marginTop:"13px",
            height:"15px",
            textAlign:"center",
            width:"140px",
            borderRadius:"18px",
            display:"flex",
            // alignItems:"center",
            // justifyContent:"center",
          };
        case "Queue":
          return {
            color: "orange",
            backgroundColor: "rgba(255, 165, 0, 0.1)",
            margin:"auto",
            marginTop:"13px",
            height:"15px",
            textAlign:"center",
            width:"140px",
            borderRadius:"18px",
            display:"flex",
            // alignItems:"center",
            // justifyContent:"center",
          };
        case "Review":
          return {
            color: "purple",
            backgroundColor: "rgba(128, 0, 128, 0.1)",
            margin:"auto",
            marginTop:"13px",
            height:"15px",
            textAlign:"center",
            width:"140px",
            borderRadius:"18px",
            display:"flex",
            // alignItems:"center",
            // justifyContent:"center",
          }  
        default:
            return {
                color: "red",
                backgroundColor: "rgba(255, 0, 0, 0.1)",
                margin:"auto",
                marginTop:"13px",
                height:"15px",
                textAlign:"center",
                width:"140px",
                borderRadius:"18px",
                display:"flex",
                // alignItems:"center",
                // justifyContent:"center",
              };
      }
    };

    const getStatusStyleMobile = (status) => {
      switch (status) {
        case "Error":
          return {
            color: "red",
            backgroundColor: "rgba(255, 0, 0, 0.1)",
            margin:"auto",
            marginTop:"13px",
            paddingLeft:"15px",
            height:"28px",
            textAlign:"center",
            width:"140px",
            borderRadius:"18px",
            display:"flex",
          };
        case "Uploaded":
          return {
            color: "blue",
            backgroundColor: "rgba(0, 0, 255, 0.1)",
            borderRadius:"18px",
            margin:"auto",
            marginTop:"13px",
            paddingLeft:"15px",
            height:"28px",
            width:"140px",
            textAlign:"center",
            fontSize:"15px",
            display:"flex",
          };
        case "Completed":
          return {
            color: "green",
            backgroundColor: "rgba(0, 128, 0, 0.1)",
            margin:"auto",
            marginTop:"13px",
            paddingLeft:"15px",
            height:"28px",
            textAlign:"center",
            width:"140px",
            borderRadius:"18px",
            display:"flex",
          };
        case "Queue":
          return {
            color: "orange",
            backgroundColor: "rgba(255, 165, 0, 0.1)",
            margin:"auto",
            marginTop:"13px",
            paddingLeft:"15px",
            height:"28px",
            textAlign:"center",
            width:"140px",
            borderRadius:"18px",
            display:"flex",
          };
        default:
            return {
                color: "orange",
                backgroundColor: "rgba(255, 165, 0, 0.1)",
                margin:"auto",
                marginTop:"13px",
                paddingLeft:"15px",
                height:"28px",
                textAlign:"center",
                width:"140px",
                borderRadius:"18px",
                display:"flex",
              };
      }
    };

    const getStatusIcon = (status) => {
        let marginRightValue = '0px'; 
        switch (status) {
            case "Completed":
                return <CheckCircleIcon style={{ color: "green", marginRight: marginRightValue }} />;
            case "Error":
                return <ErrorIcon style={{ color: "red", marginRight: marginRightValue }} />;
            case "Queue":
                return <HourglassEmptyIcon style={{ color: "orange", marginRight: marginRightValue }} />;
            case "Uploaded":
                return <CloudUploadIcon style={{ color: "blue", marginRight: marginRightValue }} />;
            default:
                return <HourglassEmptyIcon style={{ color: "orange", marginRight: marginRightValue }} />;
        }
    };

    const handlePageChange = (newPage) => {
      setPage(newPage);
    };


    const calculatePageRange = () => {
      const half = Math.floor(maxPageNumbersToShow / 2);
      let start = Math.max(0, page - half);
      let end = Math.min(totalPages, start + maxPageNumbersToShow);
  
      if (end - start < maxPageNumbersToShow) {
        start = Math.max(0, end - maxPageNumbersToShow);
      }
  
      return { start, end };
    };
  
    const { start, end } = calculatePageRange();
  


    // const handleEdit = (mains_answer_sheet_hash, pdf_name) => {
    //   if (!mains_answer_sheet_hash || !pdf_name) {
    //     console.error('Invalid parameters for handleEdit');
    //     return;
    //   }
    //   const test = `/edit/${mains_answer_sheet_hash}/${loggedInUserDetails.user_id}/${pdf_name}`;
    //   window.open(test, "_blank");
    // };




    return (
        <>
            <section className="dashboard">
                  <div>
                    <h2 className="dashboard-title">Answer Review Dashboard</h2>
                  </div>
                <div className="data-overview">
                    <div className="dropdown-container-dash">
                 
        </div>

            {/* widget for pdf */}
            {selectedOption === "pdf" && (
              <div className="widgets-container">
                    {Object.keys(widgetData).map((key) => (
                        <article className="widget-card-dash">
        <div className="widget-content-dash">
        <h3 className="widget-title-dash">{key.charAt(0).toUpperCase() + key.slice(1)}</h3>
          <p className="widget-count-dash">{widgetData[key].count}</p>
        </div>
        <div className="widget-image-con-dash">
        <img className="widget-image-dash" src={widgetData[key].imgUrl} alt={key} />
        </div>
      </article>
            ))}
          </div>
            )}
        </div>
      </section>
            
            
            <div className="uploads-review-section-b2b">
                <h1 className="uploads-review-heading-dash">Uploaded Answers</h1>

                {/* mobile view for pdf dashboard */}
                {selectedOption === "pdf" && (
  <>
    <div className="uploads-table-container-dash-mobile">
      {pdfData.answer_sheet_status.map((pdf) => (
        <section className="submission-details-dash-mobile" key={pdf.mains_answer_sheet_hash}>
          <div className="details-row-dash-mobile">
            <div className="details-column-dash-mobile">
              <div className="details-date-dash-mobile">
                {pdf.created_at ? format(parseISO(pdf.created_at), "d MMM''yy, h:mm a") : 'No date available'}
              </div>
              {/* <div className="details-label-dash-mobile">Submission Date</div> */}
            </div>
            {/* <div className="details-column-dash-mobile">
              <div className="details-id-dash-mobile">{pdf.mains_answer_sheet_hash.slice(-7)}</div>
              <div className="details-label-dash-mobile">ID</div>
            </div> */}
          </div>
          <div className="details-row-dash-mobile">
            <div className="details-column-dash-mobile">
            <div className="details-time-dash-mobile">
            <div className="details-time-dash-mobile">
              {pdf.pdf_name ? (
                pdf.pdf_name.length <= 10 ? (
                  pdf.pdf_name
                ) : (
                  `${pdf.pdf_name.substring(0, 3)}..${pdf.pdf_name.substring(pdf.pdf_name.length - 5)}`
                )
              ) : (
                'No PDF name available'
              )}
            </div>
            </div>


              {/* <div className="details-label-dash-mobile">PDF Name</div> */}
            </div>
            <div className="details-column-dash-mobile">
              <div className="status-badge-dash-mobile">
              <div className="details-time-dash-mobile">
                {pdf.subject ? (pdf.subject.length > 7 ? `${pdf.subject.substring(0, 5)}...` : pdf.subject) : "No Subject Available"}
                </div>

                {/* <div className="details-label-dash-mobile">Subject</div> */}
              </div>
            </div>
          </div>
          <div className="action-buttons-dash-mobile">
            <div className="details-column-dash-mobile">
              <div data-label="Status" className={`status-b2b ${pdf.status.toLowerCase().replace(' ', '-')}`} style={getStatusStyleMobile(pdf.status)}>
                <span>{getStatusIcon(pdf.status)}</span>
                <span>{pdf.status}</span>
              </div>
              {/* <div className="status-label-dash-mobile">Status</div> */}
            </div>
            <div className="icons-dash-mobile">
              {pdf.public_url && (
                <>
                  <div className="icon-dash-mobile" onClick={() => downloadPDF(pdf.mains_answer_sheet_hash, pdf.pdf_name)}>
                    <DownloadSimple size={20}/>
                  </div>
                  <div className="icon-dash-mobile" onClick={() => handleView(pdf.mains_answer_sheet_hash, pdf.pdf_name)}>
                    <Eye size={20}/>
                  </div>
                  <div className="icon-dash-mobile" onClick={() => handleEdit(pdf.mains_answer_sheet_hash, pdf.pdf_name)}>
                    <PencilSimple size={20}/>
                  </div>
                </>
              )}
            </div>
          </div>
        </section>
      ))}
      <div className="pagination-mobile-b2b">
        <div className="pagination-b2b">
          <button disabled={page === 0} onClick={() => setPage(prevPage => prevPage - 1)} className="prev-mobile-b2b">
            <img loading="lazy-b2b" src="https://cdn.builder.io/api/v1/image/assets/TEMP/3b494eec7ddb3ec5d923e8db5873fe82d8e98d65581f74b8a8d054f0b5321162?apiKey=7e91cb269c0144af96e9f3d2d5576b41&" alt="Previous" />
          </button>
          <div style={{ textAlign: 'center', marginTop: "10px" }}>  {page}1-{page + 1}0 of {total} items</div>
          <button disabled={pdfLength} onClick={() => setPage(prevPage => prevPage + 1)} className="next-mobile-b2b">
            <img loading="lazy-b2b" src="https://cdn.builder.io/api/v1/image/assets/TEMP/6aec2fb74b2f4f009e75e74c75df9db15b3c743f0941d77cf52e03440cdefbfc?apiKey=7e91cb269c0144af96e9f3d2d5576b41&" alt="Next" />
          </button>
        </div>
      </div>
    </div>
  </>
)}
                {selectedOption === "pdf" && (
  <div className="uploads-table-container-dash">
    <div className="flex gap-1">
    <Button
          onClick={fetchData}
          sx={{
            backgroundColor: '#2196f3',
            color: 'white', 
            '&:hover': {
              backgroundColor: '#1976d2', 
            },
            marginLeft: '10px',
          }}
        >
          Refresh
        </Button>
      <Button style={{border: '1px solid #2196f3',color: '#2196f3',marginLeft: '5px'}} variant="outlined" onClick={handleOpenBackDrop} startIcon={<FilterListIcon />}>Filter</Button>
      <FilterComponent 
        open={openBackdrop}
        onClose={handleCloseBackDrop}
        subjectFilter={subjectFilter}
        statusFilter={statusFilter}
        setSubject={setSubject}
        setStatus={setStatus}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        startDate={startDate}
        endDate={endDate}
        setFilterApplied={setFilterApplied}
      />
    </div>
    {filterApplied.length > 0 && (
      <>
      <div className="filter-container-n-a">
      {filterApplied.map((item, index) => (
        <div key={index} className="filter-item-n-a">
          <span className="filter-type-n-a">{item.type}</span>
          <span className="filter-value-n-a">{item.value}</span>
        </div>
      ))}
    </div>
      </>
    )}
                <table className="uploads-review-table-dash">
                    <thead>
                        <tr>
                        <th style={{ textAlign: 'center' }}>ID</th>
                        {pdfData.answer_sheet_status && pdfData.answer_sheet_status[0] && pdfData.answer_sheet_status[0].user_name && (
  <th style={{ textAlign: 'center' }}>User Name</th>)}
                        <th style={{ textAlign: 'center' }}>PDF Name</th>
                        <th style={{ textAlign: 'center' }}>Subject</th>
                        <th style={{ textAlign: 'center' }}>Date of Submission</th>
                        <th style={{ textAlign: 'center' }}>Status</th>
                        <th style={{ textAlign: 'center' }}>Answer</th>
                        </tr>
                    </thead> 
                    <tbody>
                        {pdfData.answer_sheet_status.map((row, index) => (
                            <tr key={index}>
                                <td data-label="ID" style={{ textAlign: 'center' }}>{row.mains_answer_sheet_hash.slice(-8)}</td>
                                {row.user_name && <td data-label="PDF Name" style={{ textAlign: 'center' }}>{row.user_name}</td>}
                                <td className="tableCell" title={row.pdf_name}>
        {row.raw_pdf_url ? (
          <a 
            href={row.raw_pdf_url} 
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {row.pdf_name.slice(0, 10)}
          </a>
        ) : (
          row.pdf_name.slice(0, 10)
        )}
      </td>
                                <td data-label="Subject" style={{ textAlign: 'center' }}>{row.subject}</td>
                                <td data-label="Date of Submission" style={{ textAlign: 'center' }}>
                                 {row.created_at ? format(parseISO(row.created_at), "d MMM''yy, h:mm a") : 'No date available'}
                                </td>
                                <td data-label="Status" className={`status-b2b ${row.status.toLowerCase().replace(' ', '-')}`} style={getStatusStyle(row.status)}>
                                    <span>{getStatusIcon(row.status)}</span>
                                    <span>{row.status}</span>
                                </td>
                                {row.public_url && 
                                <td data-label="Action" style={{ textAlign: 'center' }}>
                                    <div style={{ paddingLeft:"20px" }} className="actions-b2b">
                                        {loadingActions[row.mains_answer_sheet_hash] ? (
                                            <CircularProgress size={20} color="inherit" />
                                        ) : (
                                            <>
                                            <PencilSimple size={22} onClick={() => handleEdit( row.mains_answer_sheet_hash, row.pdf_name)} />
                                                <Eye size={22} onClick={() => handleView( row.mains_answer_sheet_hash, row.pdf_name)} />
                                                <DownloadSimple size={22} onClick={() => downloadPDF(row.mains_answer_sheet_hash, row.pdf_name)} />

                                            </>
                                        )}
                                    </div>
                                </td>}
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div style={{justifyContent: 'center'}} className="pagination-b2b">
                <div style={{ height: 53 }}>
  <div colSpan={5} style={{ textAlign: 'center' }}>
    <button
      className="previous-next-button"
      onClick={() => handlePageChange(Math.max(page - 1, 0))}
      disabled={page === 0}
      aria-label="Previous Page"
    >
      Previous
    </button>
    {[...Array(end - start)].map((_, index) => (
      <button
        key={index}
        className={`page-button ${page === start + index ? 'active' : ''}`}
        onClick={() => handlePageChange(start + index)}
        aria-label={`Page ${index + 1}`}
      >
        {start + index + 1}
      </button>
    ))}
    <button
      className="previous-next-button"
      onClick={() => handlePageChange(Math.min(page + 1, totalPages - 1))}
      disabled={page >= totalPages - 1}
      aria-label="Next Page"
    >
      Next
    </button>
  </div>
</div>

                </div>
                <div className="pagination-mobile-b2b">
                <div className="pagination-b2b">
                    <button disabled={page===0} onClick={() => setPage(prevPage => prevPage - 1)} className="prev-mobile-b2b">
                        <img loading="lazy-b2b" src="https://cdn.builder.io/api/v1/image/assets/TEMP/3b494eec7ddb3ec5d923e8db5873fe82d8e98d65581f74b8a8d054f0b5321162?apiKey=7e91cb269c0144af96e9f3d2d5576b41&" alt="Previous" />
                    </button>
                    <div style={{ textAlign: 'center', marginTop: "10px" }}>  {page}1-{page+1}0 of {total} items</div>
                    <button  disabled={pdfLength} onClick={() => setPage(prevPage => prevPage + 1)} className="next-mobile-b2b">
                        <img loading="lazy-b2b" src="https://cdn.builder.io/api/v1/image/assets/TEMP/6aec2fb74b2f4f009e75e74c75df9db15b3c743f0941d77cf52e03440cdefbfc?apiKey=7e91cb269c0144af96e9f3d2d5576b41&" alt="Next" />
                    </button>
                </div>
                </div>
                </div>
                )}               
            </div>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity="success" variant="filled" sx={{ width: '100%' }}>
                    Your PDF has been downloaded
                </Alert>
            </Snackbar>
        </>


    );
}
const FilterComponent = ({ open, onClose, subjectFilter, statusFilter, setSubject, setStatus, setFilterApplied,  startDate, endDate, setStartDate, setEndDate, }) => {
  
  const [selectedSubject, setSelectedSubject] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [onScreen, setOnScreen] = useState('date');
  const [dateFilterOption, setDateFilterOption] = useState('lastSevenDays');
  const [customStartDate, setCustomStartDate] = useState(startDate); 
  const [customEndDate, setCustomEndDate] = useState(endDate);
  // const [statuses] = useState(['Error', 'Completed', 'Uploaded', 'Uploading', 'Review']);
  // const [subjects] = useState(['GS', 'GS4', 'ESSAY', 'SOCIOLOGY', 'ANTHROPOLOGY','TELANGANAMOVEMENT','LAW']);

  const handleTransactionStatusChange = (event) => {
    const value = event.target.name;
    setSelectedSubject((prev) =>
      prev.includes(value) ? prev.filter((status) => status !== value) : [...prev, value]
    );
  };

  const handlePaymentModeChange = (event) => {
    const value = event.target.name;
    setSelectedStatus((prev) =>
      prev.includes(value) ? prev.filter((mode) => mode !== value) : [...prev, value]
    );
  };

  const handleClearAll = () => {
    setSelectedSubject([]);
    setSelectedStatus([]);
    setDateFilterOption('');
    setStartDate(null);
    setEndDate(null);
    setCustomEndDate(null);
    setCustomStartDate(null);
    setFilterApplied([]);
  };

  const handleApply = () => {
    const appliedFilters = [];
  
    if (selectedSubject.length > 0) {
      appliedFilters.push(...selectedSubject.map(sub => ({ type: 'Subject', value: sub })));
      setSubject(selectedSubject);
    }
  
    if (selectedStatus.length > 0) {
      appliedFilters.push(...selectedStatus.map(stat => ({ type: 'Status', value: stat })));
      setStatus(selectedStatus);
    }
  
    if (dateFilterOption === 'customDate' && customStartDate && customEndDate) {
      appliedFilters.push({ type: 'Date', value: `From ${customStartDate} to ${customEndDate}` });
      setStartDate(customStartDate);
      setEndDate(customEndDate);
    } else if (customStartDate && customEndDate) {
      appliedFilters.push({ type: 'Date', value: `From ${customStartDate} to ${customEndDate}` });
      setStartDate(customStartDate);
      setEndDate(customEndDate);
    }
  
    setFilterApplied(appliedFilters);
    
    onClose(); 
  };

  const handleDateFilterChange = (event) => {
    const option = event.target.value;
    setDateFilterOption(option);
  
    switch (option) {
      case 'today':
        setCustomStartDate(dayjs().startOf('day').format('YYYY-MM-DD'));
        setCustomEndDate(dayjs().endOf('day').format('YYYY-MM-DD'));
        break;
      case 'yesterday':
        setCustomStartDate(dayjs().subtract(1, 'day').startOf('day').format('YYYY-MM-DD'));
        setCustomEndDate(dayjs().subtract(1, 'day').endOf('day').format('YYYY-MM-DD'));
        break;
      case 'lastSevenDays':
        setCustomStartDate(dayjs().subtract(6, 'day').format('YYYY-MM-DD'));
        setCustomEndDate(dayjs().format('YYYY-MM-DD'));
        break;
      case 'oneMonth':
        setCustomStartDate(dayjs().subtract(1, 'month').format('YYYY-MM-DD'));
        setCustomEndDate(dayjs().format('YYYY-MM-DD'));
        break;
      case 'lastSixMonths':
        setCustomStartDate(dayjs().subtract(6, 'month').format('YYYY-MM-DD'));
        setCustomEndDate(dayjs().format('YYYY-MM-DD'));
        break;
      case 'customDate':
        break;
      default:
        break;
    }
  };
  

  return (
    <Dialog open={open} onClose={onClose} className="min-h-3/4" maxWidth="md" fullWidth>
      <DialogTitle className="border-b-2">
        Filters
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
      <div className="flex w-full">
        <div className="flex flex-col w-[24%] mt-4 gap-3 px-3">
        <Button
              size="large"
              onClick={() => setOnScreen("date")}
              style={{
                backgroundColor: onScreen === "date" ? "lightblue" : "white",
                color: onScreen === "date" ? "blue" : "black",
              }}
            >
              Date
            </Button>
        <Button
          size="large"
          onClick={() => setOnScreen("subject")}
          style={{
            backgroundColor: onScreen === "subject"? "lightblue" : "white",
            color: onScreen === "subject"? "blue" : "black",
          }}
        >
          Subject
        </Button>

        <Button
          size="large"
          onClick={() => setOnScreen("status")}
          style={{
            backgroundColor: onScreen === "status"? "lightblue" : "white",
            color: onScreen === "status"? "blue" : "black",
          }}
        >
          Status
        </Button>
        </div>
        <div className="flex-1 border-l-2 p-4" style={{ minHeight: '400px' }}>
            {onScreen === 'subject' && (
              <>
             <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedSubject.length === subjectFilter.length}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedSubject(subjectFilter);
                      } else {
                        setSelectedSubject([]);
                      }
                    }}
                    name="selectAll"
                    sx={{
                        color: '#757575', 
                        '&.Mui-checked': {
                          color: '#1976d2', 
                        },
                      }}
                  />
                }
                label="Select All"
                style={{
                  color: selectedSubject.length === subjectFilter.length ? 'blue' : 'black',
                  borderRadius: '8px',
                  padding: '10px',
                }}
              />
              <FormGroup style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '10px' }}>
                {subjectFilter.map((sub) => (
                  <FormControlLabel
                    key={sub}
                    control={
                      <Checkbox
                        checked={selectedSubject.includes(sub)}
                        onChange={handleTransactionStatusChange}
                        name={sub}
                        sx={{
                        color: '#757575', 
                        '&.Mui-checked': {
                          color: '#1976d2', 
                        },
                      }}
                      />
                    }
                    label={sub}
                    style={{
                      border: selectedSubject.includes(sub)? '2px solid blue' : '2px solid lightgrey',
                      color: selectedSubject.includes(sub) ? 'blue' : 'black',
                      borderRadius: '8px',
                      padding: '10px',
                    }}
                  />
                ))}
              </FormGroup>
              </>
            )}
            {onScreen === 'status' && (
              <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedStatus.length === statusFilter.length}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedStatus(statusFilter);
                      } else {
                        setSelectedStatus([]);
                      }
                    }}
                    name="selectAll"
                    sx={{
                        color: '#757575', 
                        '&.Mui-checked': {
                          color: '#1976d2', 
                        },
                      }}
                  />
                }
                label="Select All"
                style={{
                  color: selectedStatus.length === statusFilter.length ? 'blue' : 'black',
                  borderRadius: '8px',
                  padding: '10px',
                }}
              />
              <FormGroup style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '10px' }}>
                {statusFilter.map((stat) => (
                  <FormControlLabel
                    key={stat}
                    control={
                      <Checkbox
                        checked={selectedStatus.includes(stat)}
                        onChange={handlePaymentModeChange}
                        name={stat}
                        sx={{
                        color: '#757575', 
                        '&.Mui-checked': {
                          color: '#1976d2', 
                        },
                      }}
                      />
                    }
                    label={stat}
                    style={{
                      border: selectedStatus.includes(stat)? '2px solid blue' : '2px solid lightgrey',
                      color: selectedStatus.includes(stat) ? 'blue' : 'black',
                      borderRadius: '8px',
                      padding: '10px',
                    }}
                  />
                ))}
              </FormGroup>
              </>
            )}
            {onScreen === 'date' && (
              <>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel>Date Filter</InputLabel>
                  <Select
                    value={dateFilterOption}
                    onChange={handleDateFilterChange}
                    label="Date Filter"
                    sx={{
                      backgroundColor: '#f5f5f5',
                      borderRadius: '8px',
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'lightgray',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'blue',
                      },
                    }}
                  >
                    <MenuItem value="today">Today</MenuItem>
                    <MenuItem value="yesterday">Yesterday</MenuItem>
                    <MenuItem value="lastSevenDays">Last Seven Days</MenuItem>
                    <MenuItem value="oneMonth">1 Month</MenuItem>
                    <MenuItem value="lastSixMonths">Last 6 Months</MenuItem>
                    <MenuItem value="customDate">Custom Date</MenuItem>
                  </Select>
                </FormControl>

                {dateFilterOption === 'customDate' && (
                  <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', gap: '20px' }}>
                    <TextField
                      label="Start Date"
                      type="date"
                      value={customStartDate}
                      onChange={(e) => setCustomStartDate(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        flex: 1,
                        '& .MuiInputBase-input': {
                          padding: '10px',
                          backgroundColor: '#f5f5f5',
                          borderRadius: '8px',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'lightgray',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'blue',
                        },
                      }}
                    />
                    <TextField
                      label="End Date"
                      type="date"
                      value={customEndDate}
                      onChange={(e) => setCustomEndDate(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        flex: 1,
                        '& .MuiInputBase-input': {
                          padding: '10px',
                          backgroundColor: '#f5f5f5',
                          borderRadius: '8px',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'lightgray',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'blue',
                        },
                      }}
                    />
                  </Box>
                )}
              </>
            )}
          </div>
      </div>
      </DialogContent>
      <DialogActions className="border-t-2">
      <Button 
  onClick={handleClearAll} 
  sx={{
    backgroundColor: 'white',
    color: 'blue',
    '&:hover': {
      backgroundColor: 'darkblue', 
    },
    padding: '8px 16px',
    borderRadius: '4px',
    textTransform: 'none',
  }}
>
  Clear All Filters
</Button>

        <Button onClick={handleApply} variant="contained" color="primary">
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DashboardContainer;
