import React, { useEffect, useState } from "react";
import ViewSDKClient from "../../ViewSDKClient";
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import annotationTemplate from "./annotationsTemplate";
import { useParams } from "react-router-dom";

const RenderMenu = ({ url }) => {
  const [annotations, setAnnotations] = useState([]);
  const [annotationsFetched, setAnnotationsFetched] = useState(false);
  const {id,user_id,pdf_name}=useParams();

  useEffect(() => {
    const fetchAnnotations = async () => {
      try {
        const response = await axios.post(
          "https://collectorbabu.com/api/mains/mains_get_reviews_in_text",
          {
            user_id: user_id,
            mains_answer_sheet_hash: id
          },
          {
            headers: {
              "Content-Type": "application/json",
              "accept": "application/json"
            }
          }
        );
        const annotationsResponse = response.data;
        const annotationsData = annotationsResponse.map(annotation => ({
          page_number: annotation.page_number,
          annotations: annotation.annotations || ""
        }));
        const list_of_annotations = annotationsData.map((each, index) => {
          const annotation = JSON.parse(JSON.stringify(annotationTemplate));
          annotation.id = `${uuidv4()}-${Date.now()}`;
          annotation.bodyValue = each.annotations;
          annotation.target.selector.node.index = index;
          return annotation;
        });
        list_of_annotations.pop()
        setAnnotations(list_of_annotations);
        setAnnotationsFetched(true);
      } catch (error) {
        console.error("Error fetching annotations:", error);
      }
    };

    fetchAnnotations();
  }, [url]);

  useEffect(() => {
    if (annotationsFetched) {
      const loadPDF = async () => {
        try {
          const viewSDKClient = new ViewSDKClient();
          await viewSDKClient.ready();
          viewSDKClient.previewFile("pdf-div", {
            defaultViewMode: "FULL_WIDTH",
            showAnnotationTools: false,
            showLeftHandPanel: false,
            showPageControls: false,
            showDownloadPDF: false,
            showPrintPDF: false,
            enableAnnotationAPIs: false,
            includePDFAnnotations: false,
            enableAnnotationEvents: false,
            downloadWithAnnotations: false,
            printWithAnnotations: false,
          }, url, pdf_name).then(adobeViewer => {
            adobeViewer.getAnnotationManager().then(annotationManager => {
              annotationManager.addAnnotationsInPDF(annotations)
                .then(result => console.log(result))
                .catch(error => console.log(error));
            }).catch(error => console.log(error));
          });
        } catch (error) {
          console.log(error);
        }
      };

      loadPDF();
    }
  }, [annotationsFetched, url]);



  return (
    <div
      style={{ height: "100vh" }}
      id="pdf-div"
      className="full-window-div border border-gray-100 h-screen"
    ></div>
  );
};

export default RenderMenu;

