import * as React from "react";
import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../../UserContext";
import axios from "axios";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { endpoint } from "../../apis";

const Login = (props) => {
  const [showLoginProgressBar, setShowLoginProgressBar] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const location = useLocation();
  let navigate = useNavigate();
  let [loggedInUserDetails, setLoggedInUserDetails] =
    React.useContext(UserContext);

  useEffect(() => {
    let name = Cookies.get("user_name");
    let user_id = Cookies.get("user_id");
    let email = Cookies.get("email");

    if (name && user_id && email) {
      let apiData = {
        name: name,
        user_id: user_id,
        email: email,
      };

      // internalLogin(apiData, undefined, false);
    }
  }, []);

  const handleLogin = async () => {
    setShowLoginProgressBar(true);

    let apiData = {
      email: username,
      password: password,
    };

    internalLogin(apiData, true);
  };

  const internalLogin = async (data, userInfo=null ,setCookie = true) => {
    try {
      let config = {
        method: "post",
        url: `${endpoint}/user/login`,
        headers: {
          token: "87957bdf-8fb3-4afc-a5c7-4ad62f785a60",
          "Content-Type": "application/json",
        },
        data: data,
      };

      let response = await axios(config);
      setShowLoginProgressBar(false);

      if (setCookie) {
        setLoggedInUserDetails({
          user_id: response.data.user_id,
          premium: response.data.premium,
          name: response.data.name,
        });

        Cookies.set("google_info", JSON.stringify(response.data), {
          expires: 90,
        });
        Cookies.set("user_name", response.data.name, { expires: 90 });
        Cookies.set("user_id", response.data.user_id, { expires: 90 });
        Cookies.set("email", username, { expires: 90 });
        navigate(location.state ? location.state.from : "/");
      } else {
        if (response.data.user_id === data.user_id) {
          setLoggedInUserDetails({
            ...JSON.parse(Cookies.get("google_info")),
            user_id: data.user_id,
            premium: response.data.premium,
            name:response.data.name
          });
          navigate(location.state ? location.state.from : "/");
        }
      }
    } catch (error) {
      setShowLoginProgressBar(false);
    }
  };

  return (
    <>
      {showLoginProgressBar ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
          style={{ minHeight: "100vh" }}
        >
          <CircularProgress color="info" />
          {/* 
            add text for loader here
            <Typography></Typography>
          */}
        </Box>
      ) : (
        <Grid container component="main" justifyContent="space-between">
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{ p: 4 }}
            justifyContent={"center"}
            alignItems="center"
          >
            <img
              src="\images\KPIASlogo.png"
              alt="Background"
              style={{
                width: "100%",
                height: "250px",
                objectFit: "cover",
                objectPosition: "center",
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Login
              </Typography>

              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                autoFocus
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                sx={{color:"blue"}}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                color="secondary"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={handleLogin}
              >
                Log in
              </Button>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Login;
